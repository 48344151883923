<template>
  <section class="category-block">
    <div class="category-title-block pb-4">
      <div class="category-title-name">
        <BaseSpriteIcon v-if="categoryIcon" :icon-name="categoryIcon" />
        <div class="category-title-text">
          {{ categoryNameLocalized }}
          <span class="category-title-level">{{ levelLabel }}</span>
        </div>
      </div>
    </div>
    <div class="horizontal-list mb-6">
      <div v-for="(item, index) in publications" :key="index">
        <LibraryItem
          v-if="item.id"
          class="category-item"
          horizontal
          :publication="item"
        />
      </div>
    </div>
  </section>
</template>
<script>
import publicationUtils from '@/services/utils/publicationUtils';

import LibraryItem from '@/components/views/LibraryItem/LibraryItem.vue';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';

export default {
  name: 'CategoryHorizontal',
  components: {
    LibraryItem,
    BaseSpriteIcon
  },
  props: {
    levelLabel: {
      type: String
    },
    categoryName: {
      type: String,
      required: true
    },
    categoryIcon: {
      type: String
    },
    publications: {
      type: Array,
      required: true
    }
  },
  computed: {
    categoryNameLocalized() {
      const localizationKey = publicationUtils.getCategoryLocalizationKey(
        this.categoryName,
        this.$t.bind(this)
      );
      return localizationKey;
    }
  }
};
</script>

<style src="./Category.less" lang="less"></style>
