<template>
  <div class="library-list -no-padd">
    <div v-if="recentCategory">
      <CategoryHorizontal
        :level-label="recentCategory.levelLabel"
        :category-name="recentCategory.categoryName"
        :category-icon="'ico-time-history-24-outlined'"
        :publications="recentCategory.publications"
        @libraryItemEvent="libraryItemEventHandler"
        @categoryEvent="categoryEventHandler"
      />
    </div>
    <div
      v-for="group in otherCategories"
      :key="group.categoryName"
      dense
      class="category-item-block"
    >
      <Category
        :level-label="group.levelLabel"
        :category-name="group.categoryName"
        :publications="group.publications"
        :custom-counter="getCustomCounter(group.categoryName)"
        @libraryItemEvent="libraryItemEventHandler"
        @categoryEvent="categoryEventHandler"
      />
    </div>
  </div>
</template>

<script>
import CustomCategoriesEnum from '@shared/enums/CustomCategoriesEnum';
import Category from '@/components/views/LibraryView/Category.vue';
import CategoryHorizontal from '@/components/views/LibraryView/CategoryHorizontal.vue';
import publicationUtils from '@/services/utils/publicationUtils';

export default {
  name: 'LibraryViewByCategory',
  components: {
    Category,
    CategoryHorizontal
  },
  props: {
    publications: {
      type: Array,
      required: true
    },
    customCounters: Object
  },
  computed: {
    groupedPublications() {
      return publicationUtils.groupPublicationsByCategory(
        this.publications,
        this.$store
      );
    },
    recentCategory() {
      return this.groupedPublications.find(
        group => group.categoryName === CustomCategoriesEnum.RECENT
      );
    },
    otherCategories() {
      return this.groupedPublications.filter(
        group => group.categoryName !== CustomCategoriesEnum.RECENT
      );
    }
  },
  methods: {
    libraryItemEventHandler(payload) {
      this.$emit('libraryItemEvent', payload);
    },
    categoryEventHandler(payload) {
      this.$emit('categoryEvent', payload);
    },
    getCustomCounter(categoryName) {
      return this.customCounters?.[categoryName];
    }
  }
};
</script>

<style lang="less" src="./LibraryViewByCategory.less"></style>
